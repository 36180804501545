import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { IconSetName } from "../../constants/commonData";
import { useTranslation } from "react-i18next";

const BarChartHighChart = ({ dataset = {}, title, barClick }) => {
  const { t } = useTranslation()
  let [options, setOptions] = useState({});
  let [noData, setNoData] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     // setIsMobile(window.innerWidth <= 768);
  //     if (window.innerWidth <= 768) {
  //       setOptions((prevOptions) => ({
  //         ...prevOptions,
  //         // title:{...prevOptions.title, text: t("dashboard2PieChartTitle")},
  //         // colors: generateColors(color),
  //         // lang: {
  //         //   thousandsSep: ",",
  //         // },
  //         series: [
  //           {
  //             ...prevOptions.series?.[0],
  //             pointWidth: 20,
  //           },
  //         ],
  //       }))
  //     } 
  //     // else {
  //     //   setIsSidebarOpen(false); // Close sidebar initially on mobile
  //     // }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);


  useEffect(() => {
    // Define the chart configuration
    const categoriesData = [];
    const seriesData = [];
    if (dataset && Object.keys(dataset).length) {
      for (let index in dataset.colors) {
        let c = dataset.colors[index];
        let y = dataset.values[index];
        let d = dataset.labels2[index];
        let ec = dataset.labels[index]
        let n = dataset.industryNames[index]
        let obj = { y: y, color: c, name: n, code: ec };
        seriesData.push(obj);
        categoriesData.push(d);
      }
      setNoData(false);
    } else {
      setNoData(true);
    }
    const newOptions = {
      chart: {
        type: "column",
      },
      accessibility: {
        enabled: false
    },
      tooltip: {
        formatter: function () {
          return "<b>" + t(this.point.name) + "</b><br/>" +"<b>" + `${t("naics")} ${this.point.code}` + "</b><br/>" + `${t("establishments")}: ` + this.y.toLocaleString('en-CA');
        },
      },
      legend: {
        enabled: false, // Disable the legend
      },
      credits: {
        enabled: false,
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: categoriesData,
        labels: {
          formatter: function () {
            let iconName = this.value.replace(/ /g, "");
            let icons = `<img src="${IconSetName[iconName]}" alt="SI" height=${window.innerWidth <= 768 ?  "15px" : "34px"} width=${window.innerWidth <= 768 ?  "15px" : "34px"} />`;
            return icons;
        },
          useHTML: true, // Enable HTML for labels
        },
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: t("dashboard2SectorChartYAxisTitle"),
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
          borderRadius: {
            radius: 20,
          },
        },
        series: {
          point: {
            events: {
              click: function () {
                // Your function to perform on click of a column bar
                barClick(this.index);
              },
            },
          },
        },
      },
      series: [
        {
          pointWidth: 40,
          data: seriesData,
        },
      ],
    };
    setOptions(newOptions);
  }, [dataset, t]);

  return (
    <>
      {noData ? (
        <div className="text-danger">No Data , Please Select Different Filter</div>
      ) : (
        <div className="establishment-barchart">
        <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </>
  );
};

export default BarChartHighChart;
