import React, { useEffect, useState } from 'react';

export const Loader = ({ show }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (show) {
            timer = setTimeout(() => {
                setLoading(show);
            }, 500);
        } else {
            setLoading(show);
        }
        return () => {
            clearTimeout(timer); 
        };
    }, [show]);
    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}
        </>
    );
};
