import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./translation/i18n"
import { I18nextProvider } from "react-i18next";
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';

const INTERCOM_APP_ID = "bgxrllrv"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
 
    <I18nextProvider>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <App />
      </IntercomProvider>
    </I18nextProvider>
  
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
