import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import httpClient from "../../../services/httpClient.ts";
import API_URLS from "../../../constants/apiUrls.ts";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import ConfirmationDialog from "../../../components/ConfirmationDialog.tsx";
import { useTranslation } from "react-i18next";

const dropzoneStyles = {
  border: "dashed #CACACA",
  height: "140px",
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const fileTypes = [
  {
    type: "BUSINESS_WITH_EMPLOYEE_SIZE",
    name: "Business with employees",
    languageKey: "uploadDocument.fileType.businessWithEmployeeSize",
    url: API_URLS.businessWithEmployees,
    allowedExtension: "csv",
  },
  {
    type: "BUSINESS_WITHOUT_EMPLOYEE_SIZE",
    name: "Business without employees",
    languageKey: "uploadDocument.fileType.businessWithoutEmployeeSize",
    url: API_URLS.businessWithoutEmployees,
    allowedExtension: "csv",
  },
  {
    type: "FPSC_ESTABLISHMENTS",
    name: "FPSC Establishments",
    languageKey: "uploadDocument.fileType.fpscEstablishments",
    url: API_URLS.fpscEstablishments,
    allowedExtension: "xlsx",
  },
  {
    type: "GENDER_BREAKDOWN",
    name: "Gender Breakdown by Age",
    languageKey: "uploadDocument.fileType.genderBreakdown",
    url: API_URLS.genderAgeBreakdown,
    allowedExtension: "xlsx",
  },
  {
    type: "IMMIGRANT_BREAKDOWN",
    name: "Immigrant Breakdown",
    languageKey: "uploadDocument.fileType.immigrantBreakdown",
    url: API_URLS.immigrantBreakdown,
    allowedExtension: "xlsx",
  },
  {
    type: "EDUCATIONAL_PROGRAMS",
    name: "Educational Program",
    languageKey: "uploadDocument.fileType.educationalPrograms",
    url: API_URLS.educationalProgramUpload,
    allowedExtension: "csv",
  },
  {
    type: "EMPLOYMENT_DATA",
    name: "Employment Data",
    languageKey: "uploadDocument.fileType.employmentData",
    url: API_URLS.employmentDataUpload,
    allowedExtension: "csv",
  },
];
const UploadDocumentModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [selectedSheetType, setSelectedSheetType] = useState(0);
  const [payload, setPayload] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (show) {
      clearErrors();
      reset();
      setSelectedFile(null);
      setSelectedFileType(null);
    }
  }, [show]);
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "all" });

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert("Please upload only one file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    let url = API_URLS.uploadFile.replace(
      ":type",
      selectedFileType?.allowedExtension
    );
    httpClient({
      method: "POST",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(({ result, error }) => {
      if (result && result.file) {
        setSelectedFile(result.file);
      }
    });
  };

  const saveFile = (data) => {
    if (!selectedFile) {
      alert("Please upload a file.");
      return;
    }
    if (!selectedFileType) {
      alert("Please select a file type.");
      return;
    }

    let { period, year } = data;

    let payload = {
      period,
      year,
      file: selectedFile?._id,
      fileType: selectedFileType.type,
    };
    setPayload(payload);

    httpClient({
      method: "POST",
      url: API_URLS.verifyDocument,
      data: { period, year, type: payload.fileType },
    }).then(({ result, error }) => {
      if (result) {
        if (!result.exists) {
          saveDocument(payload);
        } else {
          setShowConfirmation(true);
        }
      }
    });
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleConfirm = () => {
    setShowConfirmation(false);
    saveDocument(payload);
  };

  const saveDocument = (payload) => {
    httpClient({
      method: "POST",
      url: selectedFileType.url,
      data: payload,
    }).then(({ result, error }) => {
      if (result) {
        onHide();
      }
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        className={showConfirmation ? `z-index-0` : ""}
      >
        <Form onSubmit={handleSubmit(saveFile)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("uploadDocument.modalTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="custom-form-group">
              <label>{t("uploadDocument.inputTitle.fileType")} *</label>
              <select
                className={`form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg ${errors.fileType ? "is-invalid" : ""
                  }`}
                {...register("fileType", {
                  required: "Required",
                })}
                onChange={(e) => {
                  setSelectedFileType(
                    e.target.value ? JSON.parse(e.target.value) : null
                  );
                  if (!e.target.value) {
                    setSelectedFile(null);
                  }
                }}
              >
                <option value={""}>
                  {t("uploadDocument.fileType.selectType")}
                </option>
                {fileTypes.map((type) => (
                  <option value={JSON.stringify(type)}>
                    {t(type.languageKey)}
                  </option>
                ))}
              </select>
              {errors.period && (
                <div className="invalid-feedback">
                  {errors.fileType?.message}
                </div>
              )}
            </div>
            {!(
              selectedFileType?.type == "EDUCATIONAL_PROGRAMS" ||
              selectedFileType?.type == "EMPLOYMENT_DATA"
            ) && (
                <div className="custom-form-group">
                  <label>{t("uploadDocument.inputTitle.period")} *</label>
                  <select
                    className={`form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg ${errors.period ? "is-invalid" : ""
                      }`}
                    {...register("period", {
                      required: "Required",
                    })}
                  >
                    <option selected value={""}>
                      {t("uploadDocument.period.selectPeriod")}
                    </option>
                    <option value={1}>{t("uploadDocument.period.june")}</option>
                    <option value={2}>
                      {t("uploadDocument.period.december")}
                    </option>
                  </select>
                  {errors.period && (
                    <div className="invalid-feedback">
                      {errors.period?.message}
                    </div>
                  )}
                </div>
              )}

            <div className="custom-form-group">
              <label>Year *</label>
              <select
                className={`form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg ${errors.year ? "is-invalid" : ""
                  }`}
                {...register("year", {
                  required: "Required",
                })}
              >
                <option selected value={""}>
                  Select Year
                </option>

                {Array.from(
                  { length: new Date().getFullYear() - 2014 },
                  (_, index) => 2015 + index
                )
                  .reverse()
                  .map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
              {errors.year && (
                <div className="invalid-feedback">{errors.year.message}</div>
              )}
            </div>

            {selectedFileType && (
              <div {...getRootProps()} style={dropzoneStyles}>
                <input {...getInputProps()} />
                <p>
                  {selectedFile && selectedFile?.originalName
                    ? `Drag and drop another file here, or click to select a different file.`
                    : `Drag and drop a file here, or click to select a file.`}
                </p>
              </div>
            )}
            {selectedFile && (
              <div>
                <p className="m-1">File Name: {selectedFile.originalName}</p>
                {selectedFile.size && (
                  <p className="m-1">
                    File Size: {(selectedFile.size / (1024 * 1024)).toFixed(2)}{" "}
                    MB
                  </p>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="button button-secondary" variant={null} onClick={onHide}>
              Close
            </Button>
            <Button className="button" variant={null} type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ConfirmationDialog
        message="The file already exists for the given type, period and year. Are you sure you want to replace the document?"
        subMessage="Replacing the document will overrite the records as well."
        show={showConfirmation}
        handleClose={handleCloseConfirmation}
        confirmEventTriggered={handleConfirm}
      />
    </>
  );
};
export default UploadDocumentModal;
