import React, { useEffect, useState, useRef } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { OverlayTrigger, Tooltip, Table, Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import PaginationComponent from "./PaginationComponent.js";

const TableByApprenticeship = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([]);
  const [resultData, setResultData] = useState([])
  const [studyField, setStudyField] = useState('')
  const [selectedProvince, setSelectedProvince] = useState('')
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const clearAllRef = useRef(null);

  const handlePaginationData = (studyFieldId, provinceId) => {
    setStudyField(studyFieldId)
    setSelectedProvince(provinceId)
    setShowPaginationComponent(true)
  }

  const handlePaginationClearAll = () => {
    clearAllRef.current?.();
  }

  useEffect(() => {
    let url = API_URLS.eductaionalProgramByApprenticeship;

    httpClient({
      method: "get",
      url,
    }).then(({ result, error }) => {
      if (result) {
        let resultantArr = [];
        let iterableArr = result.counts;
        for (let i of iterableArr) {
          let obj1 = {};
          obj1["title"] = capitalizeFirstLetter(t(i.fieldOfLanguageKey));
          obj1["id"] = i.fieldOfStudyId
          obj1["rowSpan"] = i.geographies.length;
          for (let g in i.geographies) {
            let obj2 = {};
            obj2["id"] = i.fieldOfStudyId
            obj2["province"] = capitalizeFirstLetter(
              t(i.geographies[g].geographyLanguageKey)
            );
            obj2["provinceId"] = i.geographies[g].geographyId
            obj2["count"] = i.geographies[g].count;
            resultantArr.push(Number(g) === 0 ? { ...obj1, ...obj2 } : obj2);
          }
        }
        setResultData(result.counts.map(({ fieldOfStudyId, ...rest }) => ({
          id: fieldOfStudyId, // Rename fieldOfStudyId to id
          ...rest // Include the rest of the properties unchanged
        })));
        setData(resultantArr);
      }
    });
  }, [t]);

  // return (
  //   <>
  //     {showPaginationComponent ?
  //       (<>
  //         <div className="row gx-0">
  //           <div className="d-flex justify-content-between">
  //             <button
  //               onClick={() => setShowPaginationComponent(!showPaginationComponent)}
  //               className="btn btn-link text-theme font-small ps-2 m-0"
  //             >
  //               {t("educationalProgram.tabApprenticeOffered.paginationComponent.backButtonTable")}

  //             </button>
  //             <button className="clear_btn" onClick={handlePaginationClearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
  //               {t("commonSubHeadingClearAll")}
  //             </button>
  //           </div>
  //         </div>
  //         <PaginationComponent
  //           item={studyField}
  //           apprenticeship={true}
  //           province={selectedProvince}
  //           clearAllRef={clearAllRef}
  //         />
  //       </>)
  //       :
  //       <>
  //         <h6 className="py-3 px-2">
  //           {t("educationalProgram.tabApprenticeOffered.title")}
  //         </h6>
  //         <div className="d-flex gap-2 flex-wrap justify-content-between">
  //           {resultData && resultData.map((data, index) => {
  //             // Create a new object with 'id' instead of 'fieldOfStudyId'
  //             const { fieldOfStudyId: id, ...rest } = data;
  //             return (
  //               <Card className="custom-card">
  //                 <h3 className="custom-card-title">{capitalizeFirstLetter(t(data.fieldOfLanguageKey))}</h3>
                  
  //                 <div className="table-container">
  //                   <div className="table-header">
  //                     <table className="custom-card-table">
  //                       <thead>
  //                         <tr>
  //                           <th>{t(
  //                             "educationalProgram.tabApprenticeOffered.tableColumnHeading.ProvinceTerritory"
  //                           )}</th>
  //                           <th>{t(
  //                             "educationalProgram.tabApprenticeOffered.tableColumnHeading.Programs"
  //                           )}</th>
  //                         </tr>
  //                       </thead>
  //                     </table>
  //                   </div>
  //                   <div className="table-body">
  //                     <table className="custom-card-table">
  //                       <tbody>
  //                         {data.geographies && data.geographies.map((tableData, index1) => (
  //                           <OverlayTrigger
  //                             key={index}
  //                             delay={{ hide: 450, show: 300 }}
  //                             overlay={(props) => (
  //                               <Tooltip {...props}>
  //                                 {t("clickForProgramDetails")}
  //                               </Tooltip>
  //                             )}
  //                             placement= {index % 2 === 0 ? 'right' : 'left'}
  //                           >
  //                             <tr key={index} style={{ cursor: "pointer" }} onClick={() => handlePaginationData(data, tableData.geographyId)}>
  //                               <td>{capitalizeFirstLetter(t(tableData.geographyLanguageKey))}</td>
  //                               <td>{tableData.count}</td>
  //                             </tr>
  //                           </OverlayTrigger>
  //                         ))}
  //                       </tbody>
  //                     </table>
  //                   </div>
  //                 </div>
  //               </Card>
  //             )
  //           })}
  //         </div>
  //       </>
  //     }
  //   </>
  // );

  return (
    <>
      {showPaginationComponent ? (
        <>
          <div className="row gx-0 mb-3">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <button
                onClick={() => setShowPaginationComponent(!showPaginationComponent)}
                className="btn btn-link text-theme font-small ps-2 m-0"
              >
                {t("educationalProgram.tabApprenticeOffered.paginationComponent.backButtonTable")}
              </button>
              <button
                className="clear_btn"
                onClick={handlePaginationClearAll}
                style={{ fontSize: "15px", marginRight: "10px" }}
              >
                {t("commonSubHeadingClearAll")}
              </button>
            </div>
          </div>
          <PaginationComponent
            item={studyField}
            apprenticeship={true}
            province={selectedProvince}
            clearAllRef={clearAllRef}
          />
        </>
      ) : (
        <>
          <h6 className="py-3 px-2">{t("educationalProgram.tabApprenticeOffered.title")}</h6>
          <div className="row gy-3">
            {resultData &&
              resultData.map((data, index) => {
                // Create a new object with 'id' instead of 'fieldOfStudyId'
                const { fieldOfStudyId: id, ...rest } = data;
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={id}>
                    <Card className="custom-card h-100">
                      <h3 className="custom-card-title">{capitalizeFirstLetter(t(data.fieldOfLanguageKey))}</h3>
                      
                      <div className="table-container">
                        <div className="table-header">
                          <table className="custom-card-table">
                            <thead>
                              <tr>
                                <th>{t("educationalProgram.tabApprenticeOffered.tableColumnHeading.ProvinceTerritory")}</th>
                                <th>{t("educationalProgram.tabApprenticeOffered.tableColumnHeading.Programs")}</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="table-body" style={{ overflowX: 'auto' }}>
                          <table className="custom-card-table">
                            <tbody>
                              {data.geographies &&
                                data.geographies.map((tableData, index1) => (
                                  <OverlayTrigger
                                    key={index1}
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        {t("clickForProgramDetails")}
                                      </Tooltip>
                                    )}
                                    // placement={index % 2 === 0 ? 'right' : 'left'}
                                    placement={'top'}
                                  >
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handlePaginationData(data, tableData.geographyId)}
                                    >
                                      <td>{capitalizeFirstLetter(t(tableData.geographyLanguageKey))}</td>
                                      <td>{tableData.count}</td>
                                    </tr>
                                  </OverlayTrigger>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );  
};

export default TableByApprenticeship;
