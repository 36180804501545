import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext.js"
import Error403 from "../components/Error403.jsx"
import { Loader } from "./Loader.tsx";

const TabForEmployment = ({ getTabData, getTab, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null);
  const { permissions, joyrideSteps } = useUser()
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.tab); // State to manage active tab
  const [overflowStyle, setOverflowStyle] = useState("initial");

  const handleTab = (k) => {
    setKey(k)
    getTabData('tabcomponent', k)
    getTab(k)
  }

  const getDefaultActiveTab = () => {
    if (permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabProvince'))) {
      return 'tab1';
    }
    if (permissions.includes('workforceInsights') && permissions.includes('workforceTabTotalEmployment')) {
      return 'tab2';
    }
    if (permissions.includes('workforceInsights') && permissions.includes('workforceTabDemographicBreakdown')) {
      return 'tab3';
    }
    if (permissions.includes('workforceInsights') && permissions.includes('workforceTabComparison')) {
      return 'tab4';
    }
    // if (permissions.includes('workforceInsights') && permissions.includes('educationTabComparison')) {
    //   return 'tab5';
    // }
    return null; // Default if no permissions match
  };

  const hasPermissions = () => (
    permissions.includes('full') || (
      permissions.includes('workforceInsights') && (
        permissions.includes('workforceTabProvince') ||
        permissions.includes('workforceTabTotalEmployment') ||
        permissions.includes('workforceTabDemographicBreakdown') ||
        permissions.includes('workforceTabComparison')
      )
    )
  );

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      const defaultTab = getDefaultActiveTab();
      setKey(defaultTab);
      setLoading(false)
    }
  }, [permissions]);

   // Monitor overflow in tab container
   useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);


  if (loading) {
    return <Loader show={loading}/>;
  }

  // Show Error403 if no permissions
  if (!hasPermissions()) {
    return <Error403 />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Fixed Tabs */}
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={handleTab}
        className="custom_tab"
      >
        {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabProvince'))) && (
          <Tab eventKey="tab1" title={t('employmentInsights.tabTitle.provinceAndTerritory')}
            className="tab_container mt-2"
          />
        )}
        {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabTotalEmployment'))) && (
        <Tab eventKey="tab2" title={t('employmentInsights.tabTitle.totalEmploymentOverTime')}
          className="tab_container mt-2"
        />
        )}
        {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabDemographicBreakdown'))) && (
        <Tab eventKey="tab3" title={t('employmentInsights.tabTitle.breakdown')}
          className="tab_container mt-2"
        />
        )}
        {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabComparison'))) && (
        <Tab eventKey="tab4" title={t('commonTitleComparison')}
          className="tab_container mt-2"
        />
        )}
      </Tabs>

      {/* Scrollable Content Area */}
      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)"}}>

        {(key === "tab1" && (permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabProvince')))) && (
          <TableViewEmploymentData tabSettings={getTabData} steps={joyrideSteps?.workforceInsights?.tabProvinceTerritory} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus}/>
        )}
        {(key === "tab2" && (permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabTotalEmployment')))) && <LineChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
        {(key === "tab3" && (permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabDemographicBreakdown')))) && <PyramidChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus}/>}
        {(key === "tab4" && (permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabComparison')))) && <Comparison field={"employmentInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
      </div>
    </div>
  );
};

export default TabForEmployment;
