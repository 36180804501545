
class ErrorHandlingService {
    static handleRequestError(error) {

      // if (error.response?.status === 401) {
        // localStorage.removeItem("token");
        // window.location.reload()
      // }
      console.error('Request error:', error);
      
      throw error;
    }
  
    static handleResponseError(error) {
        
      console.error('Response error:', error);
      
      throw error;
    }
  }
  
  export default ErrorHandlingService;