import React from "react";
import { useNavigate, useLocation } from "react-router";
import logoblack from "../../../assets/fpsc-logo.png";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/GeoIcon.svg";
import { ReactComponent as SectorIcon } from "../../../assets/icons/SectorIcon.svg";
// import { ReactComponent as ComparsionIcon } from "../../../assets/icons/ComparsionIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/icons/EducationIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/DocumentIcon.svg";
import { ReactComponent as GearIcon } from "../../../assets/icons/GearIcon.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/LogoutIcon.svg";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useUser } from '../../../context/UserContext';

const Sidebar = () => {
  const { permissions, logout } = useUser();
  // console.log(permissions, 'permissions')
  const decodedToken = jwtDecode(localStorage.getItem("token")!);
  // console.log(decodedToken?.role?.permissions?.includes('establishmentInsights'))
  const navigate = useNavigate();
  const location = useLocation();
  const { shutdown } = useIntercom()
  const { t } = useTranslation()

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    logout()
    shutdown()
  };

  return (
    <>
      <div className="sidebar-wrapper" style={{ display: "flex", flexDirection: "column" }}>
       
        <a href="/" className="text-decoration-none sidebar_logo">
          <img src={logoblack} alt="" style={{ width: "154px" }} />
        </a>
       
        <div style={{ flexGrow: 1, display:"flex", justifyContent: "space-between", flexDirection:"column"}}>
        <div>
          <ul className="nav nav-pills flex-column mb-auto">
            <li><LanguageSelector /></li>
            {(permissions?.includes('establishmentInsights') || permissions?.includes('full')) && (
            <li className="nav-item">
              <Link
                to="/establishment-data"
                className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                aria-current="page"
              >
                <GlobeIcon className="icon" />
                {t("sidebarEstablishmentInsights")}
              </Link>
            </li>
            )}
            {(permissions?.includes('educationalProgramInsights') || permissions?.includes('full')) && (
            <li>
              <Link
                to="/educational-program"
                className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                aria-current="page"
              >
                <EducationIcon className="icon" />
                {t('sidebarEducationalProgramsInsights')}
              </Link>
            </li>
            )}
             {(permissions?.includes('workforceInsights') || permissions?.includes('full')) && (
            <li>
              <Link
                to="/employment-data"
                className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                aria-current="page"
              >
                <SectorIcon className="icon" />
                {t("sidebarWorkforceInsights")}
              </Link>
            </li>
             )}
            {decodedToken && (decodedToken as any).role.role === "SUPER_ADMIN" && (
              <li>
                <Link
                  to="/documents"
                  className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                  aria-current="page"
                >
                  <DocumentIcon className="icon" />
                  {t('sidebarDocuments')}
                </Link>
              </li>
            )}

          </ul>
        </div>

        <div className="sibebar_footer" style={{marginBottom:"30px"}}>
          <ul className="nav nav-pills flex-column mb-0">
            <li className="nav-item">
              <Link
                to="/settings"
                className={`nav-link ${location.pathname === "/settings" ? "active" : "link-dark"}`}
                aria-current="page"
              >
                <GearIcon className="icon" />
                {t('sidebarSettings')}
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                role="button"
                tabIndex={0}
                onClick={handleLogout}
              >
                <LogoutIcon className="icon" />
                {t('sidebarLogout')}
              </a>
            </li>
          </ul>
        </div>
        </div>
      </div>

    </>
  );
};

export default Sidebar;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router";
// import logoblack from "../../../assets/fpsc-logo.png";
// import { jwtDecode } from "jwt-decode";
// import { useTranslation } from "react-i18next";
// import { ReactComponent as GlobeIcon } from "../../../assets/icons/GeoIcon.svg";
// import { ReactComponent as SectorIcon } from "../../../assets/icons/SectorIcon.svg";
// import { ReactComponent as EducationIcon } from "../../../assets/icons/EducationIcon.svg";
// import { ReactComponent as DocumentIcon } from "../../../assets/icons/DocumentIcon.svg";
// import { ReactComponent as GearIcon } from "../../../assets/icons/GearIcon.svg";
// import LanguageSelector from "../../../components/LanguageSelector";
// import { ReactComponent as LogoutIcon } from "../../../assets/icons/LogoutIcon.svg";
// import { Link } from "react-router-dom";
// import { useIntercom } from "react-use-intercom";
// import { useUser } from '../../../context/UserContext';
// import { FiMenu } from "react-icons/fi";

// const Sidebar = () => {
//   const { permissions, logout } = useUser();
//   const decodedToken = jwtDecode(localStorage.getItem("token")!);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { shutdown } = useIntercom();
//   const { t } = useTranslation();

//   const [isMobile, setIsMobile] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Open by default on desktop

//   const handleLogout = () => {
//     localStorage.clear();
//     navigate("/");
//     logout();
//     shutdown();
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//       if (window.innerWidth > 768) {
//         setIsSidebarOpen(true); // Ensure sidebar is open on desktop view
//       } else {
//         setIsSidebarOpen(false); // Close sidebar initially on mobile
//       }
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <div 
//       className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`} 
//       style={{ display: "flex", flexDirection: "column" }}
//     >
//       <a href="/" className="text-decoration-none sidebar_logo">
//         <img src={logoblack} alt="logo" style={{ width: "154px" }} />
//       </a>

//       {/* Hamburger icon for mobile view */}
//       {isMobile && (
//         <button 
//           onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//           className="menu-toggle-button"
//           aria-label="Toggle sidebar"
//           style={{ background: "none", border: "none", cursor: "pointer", margin: "10px" }}
//         >
//           <FiMenu size={24} />
//         </button>
//       )}

//       {/* Sidebar links */}
//       {(!isMobile || isSidebarOpen) && (
//         <div className={`nav-bar ${isMobile ? 'show' : ''}`} style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
//           <div>
//             <ul className="nav nav-pills flex-column mb-auto"> 
//               <li className="nav-item"><LanguageSelector /></li>
//               {(permissions?.includes('establishmentInsights') || permissions?.includes('full')) && (
//                 <li className="nav-item">
//                   <Link
//                     to="/establishment-data"
//                     className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
//                     aria-current="page"
//                     onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//                   >
//                     <GlobeIcon className="icon" />
//                     {t("sidebarEstablishmentInsights")}
//                   </Link>
//                 </li>
//               )}
//               {(permissions?.includes('educationalProgramInsights') || permissions?.includes('full')) && (
//                 <li>
//                   <Link
//                     to="/educational-program"
//                     className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
//                     aria-current="page"
//                     onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//                   >
//                     <EducationIcon className="icon" />
//                     {t('sidebarEducationalProgramsInsights')}
//                   </Link>
//                 </li>
//               )}
//               {(permissions?.includes('workforceInsights') || permissions?.includes('full')) && (
//                 <li>
//                   <Link
//                     to="/employment-data"
//                     className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
//                     aria-current="page"
//                     onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//                   >
//                     <SectorIcon className="icon" />
//                     {t("sidebarWorkforceInsights")}
//                   </Link>
//                 </li>
//               )}
//               {decodedToken && (decodedToken as any).role.role === "SUPER_ADMIN" && (
//                 <li>
//                   <Link
//                     to="/documents"
//                     className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
//                     aria-current="page"
//                     onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//                   >
//                     <DocumentIcon className="icon" />
//                     {t('sidebarDocuments')}
//                   </Link>
//                 </li>
//               )}
//             </ul>
//           </div>

//           <div className="sidebar-footer" style={{ marginBottom: "30px" }}>
//             <ul className="nav nav-pills flex-column mb-0">
//               <li className="nav-item">
//                 <Link
//                   to="/settings"
//                   className={`nav-link ${location.pathname === "/settings" ? "active" : "link-dark"}`}
//                   aria-current="page"
//                   onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//                 >
//                   <GearIcon className="icon" />
//                   {t('sidebarSettings')}
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   role="button"
//                   tabIndex={0}
//                   onClick={handleLogout}
//                 >
//                   <LogoutIcon className="icon" />
//                   {t('sidebarLogout')}
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Sidebar;