import React, { useEffect, useState } from "react";
import FooterLayout from "../footer";
import { useLocation, useNavigate, Navigate } from "react-router";
import { RotatingLines } from "react-loader-spinner";
import Header from "../../pages/dashboard/components/Header.tsx";
import Sidebar from "./components/Sidebar.tsx";
import { useTranslation } from "react-i18next";

// import Header from "../header/header";
interface User {
  role: string;
  // Add other user properties as needed
}
const roleBasedGuard = (allowedRoles: any, userRole: any) => {
  if (allowedRoles.includes(userRole)) {
    return true;
  }
  return false;
};
const PrivateLayout = ({ component: Component, meta }: any) => {
  const { t } = useTranslation()
  const { pathname } = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [pathname]);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    let user = localStorage.getItem("user");

    if (user) {
      try {
        let parsedUser = JSON.parse(user);
        setUser(parsedUser);
      } catch (error) {
        console.error("Error parsing user data:", error);
        // Handle the error, such as setting user to null or logging out the user.
      }
    } else {
      navigate("/");
      localStorage.setItem("returnUrl", pathname);
    }
  }, [navigate,t]);

  
  if (!user) {
    return null;
  }
  return (
    <>
    {/* {roleBasedGuard(meta.allowedRoles, userRole) ? ( */}
      <main role="main" className="main" style={{overflow: "hidden"}}>
        <Sidebar />
        <div className="mainBodyWrapper" id="mainBodyWrapper" style={{ height: '100vh'}}>
          <Component></Component>
        </div>
        {/* <FooterLayout></FooterLayout> */} 
      </main>
    {/* ) : (
      <Navigate to="/" />
    )} */}
  </>
  );
};

export default PrivateLayout;
