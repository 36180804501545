import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
const MyRangeComponent = ({ rangeData, onchange, onResetRef }) => {

    // Initialize state with two values, representing the start and end of the range
    const [values, setValues] = useState([20, 80]);
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(100);

    const handleRange = (newValues) => {
        setValues(newValues)
        onchange(newValues)
    }

    const handleReset = () => {
        setValues([rangeData?.[0], rangeData?.[rangeData.length - 1]])
        onchange([rangeData?.[0], rangeData?.[rangeData.length - 1]])
    }

    useEffect(() => {
        setMinValue(rangeData?.[0])
        setMaxValue(rangeData?.[rangeData.length - 1])
        setValues([rangeData?.[0], rangeData?.[rangeData.length - 1]])
    }, [rangeData])

    useEffect(() => {
        onResetRef.current = handleReset
    }, [onResetRef, handleReset])

    return (
        <div style={{ margin: 0, padding: '10px', width: '400px' }}>
            <Range
                values={values}
                step={1}
                min={minValue}
                max={maxValue}
                onChange={(newValues) => handleRange(newValues)}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            backgroundColor: '#ccc',
                            position: 'relative'
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                height: '6px',
                                background: '#E61E28',
                                borderRadius: '3px',
                                // left: `${(values[0] / 100) * 100}%`,
                                // right: `${100 - (values[1] / 100) * 100}%`
                                left: `${((values[0] - minValue) / (maxValue - minValue)) * 100}%`,
                                right: `${100 - ((values[1] - minValue) / (maxValue - minValue)) * 100}%`,
                            }}
                        />
                        {children}
                    </div>
                )}
                renderThumb={({ props, index }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '20px',
                            width: '20px',
                            backgroundColor: '#999',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA',
                            // position: 'relative'
                        }}
                    >
                        <div className='range-slider' >
                            {values[index]}
                        </div>
                    </div>
                )
                }
            />
        </div >
    );
};
export default MyRangeComponent;