import axios from "axios";
import ErrorHandlingService from "./errorHandlingService.ts";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// let BASE_URL = 'http://localhost:3030/api/';
// BASE_URL = 'https://fpscapi.chantsit.com/';

const axiosInstance = axios.create({
  // baseURL: `${process.env.BASE_URL}`,
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  // LoadingState.show();

  return config;
}, ErrorHandlingService.handleRequestError)
// async (error) => {
//   // If the error response has a status of 401, handle token expiration
//   if (error.response && error.response.status === 401) {
//     // You can log out the user or redirect them to the login page
//     localStorage.removeItem("token"); // Clear the invalid token
//     window.location.reload()
//     // const navigate = useNavigate(); // Create the navigate function to redirect

//     // Redirect to the login page
//     // navigate("/"); // Or your login path

//     // You can also show a toast message for the user
//     // toast.error("Session expired. Please log in again.");
//   }

//   // Pass on any other errors to the error handling service
//   return ErrorHandlingService.handleRequestError(error);
// });

axiosInstance.interceptors.response.use((response) => {
  // response.data = transformData(response.data);
  // LoadingState.hide();
  return response;
}, 
ErrorHandlingService.handleRequestError

);

export default axiosInstance;
