// import React from 'react';

// const Error403 = () => {
//   return (
//     <div className="container">
//       <div className="error-container">
//         <div className="error-number">
//           <div className="error-part">4</div>
//           <div className="error-part">0</div>
//           <div className="error-part">3</div>
//         </div>
//         <div className="error-message">
//           <h1>We are Sorry...</h1>
//           <p>
//             The page you're trying to access has restricted access. Please refer to your
//             system administrator
//           </p>
//           <button className="error-button">Go Back</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Error403;

import React from 'react';

const NoPermission = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Access Denied</h2>
      <p style={styles.message}>You do not have permission to access this page.</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    color: '#333',
    backgroundColor: '#f8f9fa',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '1.2rem',
    marginTop: '10px',
  },
};

export default NoPermission;
