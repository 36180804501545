import React, { useState, useEffect } from "react";
import {
  Route,
  Navigate,
  createRoutesFromElements,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import routes from "./routes.ts";
import PublicLayout from "../layouts/PublicLayout/index.tsx";
import PrivateLayout from "../layouts/PrivateLayout/index.tsx";
import ScrollToTop from "../components/scrollTop.ts";
import { UserProvider } from '../context/UserContext';

// const roleBasedGuard = (allowedRoles: any, userRole: any) => {
//   if (allowedRoles.includes(userRole)) {
//     return true;
//   } else {
//     return false;
//   }
// };
const Navigation = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let parsedUser = JSON.parse(user);
      setUser(parsedUser);
    }
  }, []);

  const renderNestedRoutes = (routes) => {

    return (
      <>
        {routes.map(
          ({ component, path, type, to, title, children, meta }) => (
            <React.Fragment key={path}>
              {type === "public" && (
                <Route
                  path={path}
                  element={<PublicLayout component={component} />}
                />
              )}
              {type === "private" && (
                <Route path={path} element={<PrivateLayout component={component} meta={meta} />} >
                  {children && children.length && renderNestedRoutes(children)}
                </Route>
              )}

              {(type === "public" || type === "private") && (
                <Route path={path} element={<Navigate replace to={to} />} />
              )}
            </React.Fragment>
          )
        )}
      </>
    );
  };
  const router = createHashRouter(
    createRoutesFromElements(
      <>
        {renderNestedRoutes(routes)}
      </>
    )
  );

  return ( 
      <RouterProvider router={router} />
  );
};

export default Navigation;

// import React, { useState, useEffect } from "react";
// import {
//   Route,
//   Navigate,
//   createRoutesFromElements,
//   RouterProvider,
//   createHashRouter,
// } from "react-router-dom";
// import routes from "./routes.ts";
// import PublicLayout from "../layouts/PublicLayout/index.tsx";
// import PrivateLayout from "../layouts/PrivateLayout/index.tsx";
// import SetPassword from "../pages/SetPassword.tsx";
// import ScrollToTop from "../components/scrollTop.ts";

// const Navigation = () => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const user = localStorage.getItem("user");
//     if (user) {
//       const parsedUser = JSON.parse(user);
//       setUser(parsedUser);
//     }
//   }, []);

//   const hasInvitationToken = user && user.invitationToken === null;

//   const renderNestedRoutes = (routes) => {
//     return (
//       <>
//         {routes.map(
//           ({ component, path, type, to, title, children, meta }) => (
//             <React.Fragment key={path}>
//               {type === "public" && (
//                 <Route
//                   path={path}
//                   element={<PublicLayout component={component} />}
//                 />
//               )}
//               {type === "private" && (
//                 <Route
//                   path={path}
//                   element={
//                     <PrivateLayout component={component} meta={meta} />
//                   }
//                 >
//                   {children && children.length && renderNestedRoutes(children)}
//                 </Route>
//               )}
//               {(type === "public" || type === "private") && (
//                 <Route path={path} element={<Navigate replace to={to} />} />
//               )}
//             </React.Fragment>
//           )
//         )}
//       </>
//     );
//   };

//   const router = createHashRouter(
//     createRoutesFromElements(
//       <>
//         {user && hasInvitationToken && <Route path="/set-password" element={<PublicLayout component={SetPassword} />} />}
//         {renderNestedRoutes(routes)}
//       </>
//     )
//   );

//   return (
//     <>
//       <RouterProvider router={router} />
//     </>
//   );
// };

// export default Navigation;


