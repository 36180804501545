import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { capitalizeString } from "../../services/commonFn";
import { FaInfoCircle } from 'react-icons/fa';
import i18n from 'i18next';

const PieChart = ({ dataset, color, selectedBarIndex, imageSrc }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState({
    chart: {
      type: "pie",
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container';
          const tooltipId = 'icon-tooltip';

          const addIconWithTooltip = () => {
            // Remove the existing icon and tooltip if they exist
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            let existingTooltip = document.getElementById(tooltipId);
            if (existingTooltip) existingTooltip.remove();

            // Create a div for the icon
            let iconDiv = document.createElement('div');
            iconDiv.id = iconContainerId;
            iconDiv.className = 'custom-icon';

            // Create a div for the custom tooltip
            // let tooltipDiv = document.createElement('div');
            // tooltipDiv.id = tooltipId;
            // tooltipDiv.innerHTML = t("disclaimerPieChart");
            // tooltipDiv.classList.add('pie-chart-tooltip');

            // Ensure the chart container exists before appending elements
            if (chart.container) {
              // Set chart container position to relative
              chart.container.style.position = 'relative';
              chart.container.appendChild(iconDiv);
            }

            // Render the React icon inside the div
            try {
              ReactDOM.render(
                <CustomIconWithTooltip
                  iconContainerId={iconContainerId}
                  tooltipId={tooltipId}
                />,
                iconDiv
              );;
              // ReactDOM.render(<FaQuestionCircle />, iconDiv2);
            } catch (error) {
              console.error("Error rendering the icons:", error);
            }

            // Add event listeners to show and hide the tooltip
            // iconDiv.addEventListener('mouseover', () => {
            //   // let { x, y } = iconDiv.getBoundingClientRect();
            //   tooltipDiv.style.right = `10px`;
            //   tooltipDiv.style.top = `38px`;
            //   tooltipDiv.style.visibility = 'visible';
            // });

            // iconDiv.addEventListener('mouseout', () => {
            //   tooltipDiv.style.visibility = 'hidden';
            // });

            // i18n.on('languageChanged',(lng)=>{
            //   tooltipDiv.innerHTML = t("disclaimerPieChart");
            // })

            // Set the icon's position in the top-right corner
            const updateIconPosition = () => {
              if (chart.container) {
                const legendBBox = chart.legend.group.getBBox();  // Get the bounding box of the legend
                const iconPadding = 10; // Padding between the chart and the icon

                // Position the icon between the chart and legend
                iconDiv.style.right = '25px'; // Adjust padding from right as needed
                iconDiv.style.bottom = `${legendBBox.height + iconPadding}px`;
              }
            };

            updateIconPosition();
            Highcharts.addEvent(chart, 'redraw', updateIconPosition);
            window.addEventListener('resize', updateIconPosition);
          };
          addIconWithTooltip();
        }
      }
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: t("establishments"),
        data: [],
      },
    ],
  })

  const colors = [
    "#de9b61", // 3111:
    "#fbb518", // 3112:
    "#d460a2", // 3113:
    "#ef4b32", // 3114:
    "#27ade3", // 3115:
    "#e91c4d", // 3116:
    "#113f70", // 3117:
    "#663300", // 3118:
    "#119448", // 3119:
    "#804795", // 3121:
    "#0f5b2e", // 3123:
  ]
  // const disclaimer = 'Any data that shows as “unavailable” when you navigate our interactive dashboard is data that has been suppressed by Statistics Canada. Statistics Canada supresses data for a variety of reasons, including the obstacles that are sometimes encountered when dealing with the limited availability of data for the food processing industry in some parts of the country.'

  const generateColors = (color) => {
    const shades = [];
    const steps = 11; // Number of shades
    for (let i = 0; i < steps; i++) {
      const shade = new Highcharts.Color(color)
        .brighten(i / steps + 0.1)
        .get(); // Adjust brightness
      shades.push(shade);
    }
    return shades;
  };

  useEffect(() => {
    // Define the chart configuration
    const pieChartData = [];
    for (let d of dataset) {
      pieChartData.push([capitalizeString(t(d.languageKey)), d.totalCount]);
    }
    const options = 
    
    // Render the chart
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
    setOptions((prevOptions) => ({
      ...prevOptions,
      title:{...prevOptions.title, text: t("dashboard2PieChartTitle")},
      colors: generateColors(color),
      series: [
        {
          ...prevOptions.series[0],
          data: pieChartData,
        },
      ],
    }))
    // Highcharts.chart("pie-chart-container", options);
    // onMount()
  }, [dataset,t]);

  return (
    <div className="establishment-sector-insights">
      <ListGroup>
        {dataset?.length > 0 && (
          <ListGroup.Item
            style={{
              paddingLeft: "0",
              color: "white",
              backgroundColor:
                selectedBarIndex !== undefined
                  ? colors[selectedBarIndex]
                  : "#E61E28",
            }}
          >
            {imageSrc && (
              <div className="flex-start">
                <img
                  style={{ margin: "5px" }}
                  height={50}
                  src={imageSrc}
                  alt="Sector Icon"
                />
                <h5 style={{ alignSelf: "center" }}>
                  <strong>
                    {capitalizeString(
                      t(
                        dataset[0]
                          ?.parentLanguageKey
                      )
                    )}{" "}
                    ({t("naics")}{" "}
                    {
                      dataset[0]
                        ?.parentEstablishmentExternalId
                    }
                    )
                  </strong>
                </h5>
              </div>
            )}
          </ListGroup.Item>
        )}
        {/* {!!dataset?.length && ( */}
          <ListGroup.Item>
            {/* <div className="pie-chart-container" id="pie-chart-container"> */}
              <HighchartsReact highcharts={Highcharts} options={options} />
            {/* </div> */}
          </ListGroup.Item>
        {/* )} */}
        {dataset.map((subItem, index) => (
          <ListGroup.Item key={index}>
            <strong>
              {capitalizeString(t(subItem?.languageKey))} ({t("naics")}{" "}
              {subItem?.establishmentExternalId})
            </strong>
            : {subItem.totalCount}{" "}
            {capitalizeString(t("commonInsightsTextBusinesses"))}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default PieChart;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                    <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                </div> */}
              {/* <b>{t("disclaimer")}:</b>   */}
              {t("informationPieChart").split('\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>

      {/* Second Icon with Tooltip */}
      {/* <div id={`${iconContainerId}-2`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {t("helpTextMap")}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaQuestionCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div> */}
    </>
  );
};
