import React, { useEffect, useState } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import CommonHeader from "../../components/Header.tsx";
import TabEstablishment from "../../components/TabEstablishment";

const EmploymentData = () => {
  const { t } = useTranslation()
  const [joyrideValue, setJoyrideValue] = useState(null);

  const updateSettings = (tabname, settingData) => {
    let userSetting = JSON.parse(localStorage.getItem('user'))
    let data

    switch (tabname) {
      case 'tabcomponent':
        data = {
          settings: {
            ...userSetting.settings,
            establishment: {
              ...userSetting.settings.establishment,
              tab: settingData
            },
          },
        };
        break;
      case 'comparison':

        data = {
          settings: {
            ...userSetting.settings,
            establishment: {
              ...userSetting.settings.establishment,
              comparison: {
                ...userSetting?.settings?.comparison, field1: settingData
              }
            },
          },
        };

        break;
      case 'sectorYearly':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              sectorYearly: settingData
            },
          },
        };
        break;
      case 'years':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              years: settingData
            },
          },
        };
        break;
      case 'ageRange':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              ageRange: settingData
            },
          },
        };
        break;
      default:
        break;
    }

    let url = API_URLS.preloadedSettings;
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }

  const onStart = (value) => {
    setJoyrideValue(value);
  }

  return (
    <>
      <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
        <CommonHeader
          title={t("dashboardTitle")}
          subtitle={`Source: ${t("dataSourceEstablishment")}`}
          helpText={"dashboardHelpText"}
          onStart={onStart}
        />
        <div style={{ flex: 1 }}>
          <TabEstablishment getTabData={updateSettings} joyrideStatus={joyrideValue} setJoyrideStatus={setJoyrideValue} />
        </div>
      </div>
    </>
  );
};

export default EmploymentData;