import React, { useState, useEffect } from 'react'
import Joyride from 'react-joyride'
import useWindowWidth from '../hooks/useWindowWidth';

const HelpTour = ({ run, steps, onJoyrideCallback }) => {
  const windowWidth = useWindowWidth()
  const [fontSize, setFontSize] = useState(windowWidth <= 768 ? 12 : 16);
  const [isMobile, setIsMobile] = useState(windowWidth <= 768)

  const handleJoyrideCallback = (data) => {
    onJoyrideCallback(data)
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        continuous={true} // Enables automatic progression between steps with "Next" and "Back"
        locale={{
          last: 'Close', // Changes the text of the last button to "Close"
          next: 'Next',
          back: 'Back',
        }}
        styles={{
          options: {
            arrowColor: '#fff',   //#e3ffeb
            backgroundColor: '#fff',
            overlayColor: 'rgba(0,0,0,0.5)',
            primaryColor: '#e61e28',
            textColor: '#e61e28',
            width: 400, // Reduced width
            zIndex: 1000,

          },
          tooltip: {
            left: 'auto', // Reset to ensure it's auto-positioned
            right: 'auto',
          },
          tooltipContainer: {
            textAlign: 'justify',
            fontSize: fontSize,
            ...(isMobile && {
              maxHeight: 150, // Apply maxHeight only on mobile
              overflowY: 'auto',
            }),
          }
        }}
      />
    </>
  )
}

export default HelpTour