import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let language = localStorage.getItem('selectedLanguage')

i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources:{}
  });

  i18n.changeLanguage(language);

export default i18n;
