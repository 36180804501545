import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highmaps";
import canadaMap from "@highcharts/map-collection/countries/ca/ca-all.geo.json";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Tooltip, Table, Row, Col } from "react-bootstrap";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter, capitalizeString, toCamelCase } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import { FaGlobeAmericas, FaTable } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { ColorsForSector } from "../../constants/commonData";
import { IconSetName } from "../../constants/commonData";
import { Loader } from "../Loader.tsx";
import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa'; // Importing the icon from react-icons
import { FcDisclaimer } from "react-icons/fc";
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import HelpTour from "../../components/HelpTour.tsx"; 

const TableViewEmploymentData = ({ tabSettings, steps, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const spanRef = useRef(null);
  const chartRef = useRef(null);
  const [selectedYear, setSelectedYear] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.provinceTerritory?.year || 2022);
  const [showTable, setShowTable] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.provinceTerritory?.tableView || false);
  const [colData, setColData] = useState([]);
  const [years, setYears] = useState(null)
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showLoader, setLoader] = useState(false)
  const [initialDataLoading, setInitialDataLoading] = useState(false)
  const [joyrideSteps, setSteps] = useState([])
  const [helpRun, setHelpRun] = useState(false)

  const handleYearChange = (event) => {
    const { value } = event.target
    setSelectedYear(value);
    tabSettings('provinceTerritory', { year: value, tableView: showTable })
  };

  const clearAll = () => {
    setSelectedYear(years[0])
  }

  const tableGlobeView = () => {
    setShowTable(!showTable)
    if (showTable) {
      setChartOptions(prevOptions => ({
        ...prevOptions,
        chart: {
          height: "90%"
        }
      }))
    } else {
      setChartOptions(prevOptions => ({
        ...prevOptions,
        chart: {
          height: 700
        }
      }))
    }
    tabSettings('provinceTerritory', { year: selectedYear, tableView: !showTable })
  }

  const [options, setOptions] = useState({
    chart: {
      map: canadaMap,
      height: '90%',
      useHTML: true,
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container-map';
          const tooltipId = 'icon-tooltip-map';

          // const addIconWithTooltip = () => {
          //   // Remove the existing icon and tooltip if they exist
          //   let existingIconContainer = document.getElementById(iconContainerId);
          //   if (existingIconContainer) existingIconContainer.remove();

          //   let existingTooltip = document.getElementById(tooltipId);
          //   if (existingTooltip) existingTooltip.remove();

          //   // Create a div for the icon
          //   let iconDiv = document.createElement('div');
          //   iconDiv.id = iconContainerId;
          //   iconDiv.className = 'custom-icon';

          //   // Create a div for the custom tooltip
          //   let tooltipDiv = document.createElement('div');
          //   tooltipDiv.id = tooltipId;
          //   tooltipDiv.innerHTML = `<b>${t("disclaimer")}: </b>` + t("disclaimerText");
          //   tooltipDiv.classList.add('custom-tooltip');

          //   // Ensure the chart container exists before appending elements
          //   if (chart.container) {
          //     // Set chart container position to relative
          //     chart.container.style.position = 'relative';
          //     chart.container.appendChild(iconDiv);
          //     chart.container.appendChild(tooltipDiv);
          //   }

          //   // Render the React icon inside the div
          //   try {
          //     ReactDOM.render(<FaInfoCircle />, iconDiv);
          //   } catch (error) {
          //     console.error("Error rendering the icon:", error);
          //   }

          //   // Add event listeners to show and hide the tooltip
          //   iconDiv.addEventListener('mouseover', () => {
          //     // let { x, y } = iconDiv.getBoundingClientRect();
          //     tooltipDiv.style.right = `10px`;
          //     tooltipDiv.style.top = `38px`;
          //     tooltipDiv.style.visibility = 'visible';
          //   });

          //   iconDiv.addEventListener('mouseout', () => {
          //     tooltipDiv.style.visibility = 'hidden';
          //   });

          //   i18n.on('languageChanged', (lng) => {
          //     tooltipDiv.innerHTML = `<b>${t("disclaimer")}: </b>` + t("disclaimerText");
          //   })

          //   // Set the icon's position in the top-right corner
          //   const updateIconPosition = () => {
          //     if (chart.container) {
          //       iconDiv.style.right = '25px'; // 10px padding from right
          //       iconDiv.style.top = '10px'; // 10px padding from top
          //     }
          //   };

          //   updateIconPosition();
          //   Highcharts.addEvent(chart, 'redraw', updateIconPosition);
          //   window.addEventListener('resize', updateIconPosition);
          // };

          const addIconWithTooltip = () => {
            // Remove the existing container if it exists
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            // Create a parent div to hold both icons
            let parentDiv = document.createElement('div');
            parentDiv.className = 'icons-container';
            parentDiv.style.position = 'absolute'; // Position the container
            parentDiv.style.display = 'flex'
            parentDiv.style.gap = '5px'
            // parentDiv.style.right = '40px';
            // parentDiv.style.top = '10px';

            // // Create the first icon div
            // let iconDiv1 = document.createElement('div');
            // iconDiv1.id = iconContainerId + '-1';
            // iconDiv1.className = 'custom-icon';
            // iconDiv1.style.right = '10px'

            // // Create a tooltip for the first icon
            // let tooltipDiv1 = document.createElement('div');
            // tooltipDiv1.id = tooltipId + '-1';
            // tooltipDiv1.innerHTML = `<b>${t("disclaimer")}: </b>` + t("disclaimerText");
            // tooltipDiv1.classList.add('custom-tooltip');
            // tooltipDiv1.style.right = "-10px";
            // tooltipDiv1.style.top = "30px";


            // // Create the second icon div
            // let iconDiv2 = document.createElement('div');
            // iconDiv2.id = iconContainerId + '-2';
            // iconDiv2.className = 'custom-icon';

            // // Create a tooltip for the second icon
            // let tooltipDiv2 = document.createElement('div');
            // tooltipDiv2.id = tooltipId + '-2';
            // tooltipDiv2.innerHTML = t("helpTextMap");
            // tooltipDiv2.classList.add('custom-tooltip');
            // tooltipDiv2.style.right = "-35px";
            // tooltipDiv2.style.top = "30px";

            // Ensure the chart container exists before appending elements
            if (chart.container) {
              // Set chart container position to relative
              chart.container.style.position = 'relative';
              // parentDiv.appendChild(iconDiv1);
              // parentDiv.appendChild(tooltipDiv1);
              // parentDiv.appendChild(iconDiv2);
              // parentDiv.appendChild(tooltipDiv2);
              chart.container.appendChild(parentDiv);
            }

            // Render the React icons inside the divs
            try {
              ReactDOM.render(
                <CustomIconWithTooltip
                  iconContainerId={iconContainerId}
                  tooltipId={tooltipId}
                />,
                parentDiv
              );;
            } catch (error) {
              console.error("Error rendering the icons:", error);
            }

            // // Add event listeners for the tooltips
            // iconDiv1.addEventListener('mouseover', () => {
            //   tooltipDiv1.style.visibility = 'visible';
            // });
            // iconDiv1.addEventListener('mouseout', () => {
            //   tooltipDiv1.style.visibility = 'hidden';
            // });

            // iconDiv2.addEventListener('mouseover', () => {
            //   tooltipDiv2.style.visibility = 'visible';
            // });
            // iconDiv2.addEventListener('mouseout', () => {
            //   tooltipDiv2.style.visibility = 'hidden';
            // });

            // i18n.on('languageChanged', () => {
            //   tooltipDiv1.innerHTML = `<b>${t("disclaimer")}: </b>` + t("disclaimerText");
            //   tooltipDiv2.innerHTML = t("helpTextMap");
            // });

            // Update the position of the parent div
            const updateParentDivPosition = () => {
              if (chart.container) {
                parentDiv.style.right = '45px';
                parentDiv.style.top = '10px';
              }
            };

            updateParentDivPosition();
            Highcharts.addEvent(chart, 'redraw', updateParentDivPosition);
            window.addEventListener('resize', updateParentDivPosition);
          };


          addIconWithTooltip();
        }
      }

    },
    title: {
      text: '',
      align: "center",
    },
    subtitle: {
      text: "", // Add your subtitle text here
      style: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#000'
      }
    },
    lang: {
      thousandsSep: ','
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, "#faedee"],
        [0.5, "#f7929a"],
        [1, "#E61E28"],
      ],
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
      },
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}<br/>` + `<b>${t("count")}: </b>${this.point.value.toLocaleString('en-CA')}<br/>` + `<b>${t("percent")}: </b>${this.point.percentage}% <br/>`
      },
    },
    series: [
      {
        name: "",
        allAreas: false,
        states: {
          hover: {
            color: "#112144",
            borderColor: "#fff",
          },
        },
        data: [],
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.name + "<br>" + this.point.value.toLocaleString('en-CA') + " " + `(${this.point.percentage}%)`
          },
          style: {
            border: "0",
            fontSize: "9px",
          },
          allowOverlap: true
        },
      },
    ],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {

            },
          },
        },
      },
    },
  });

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: '90%',
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container-map';
          const tooltipId = 'icon-tooltip-map';

          const addIconWithTooltip = () => {
            // Remove the existing container if it exists
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            // Create a parent div to hold both icons
            let parentDiv = document.createElement('div');
            parentDiv.className = 'icons-container';
            parentDiv.style.position = 'absolute'; // Position the container
            parentDiv.style.display = 'flex'
            parentDiv.style.gap = '5px'

            if (chart.container) {
              // Set chart container position to relative
              chart.container.style.position = 'relative';
              chart.container.appendChild(parentDiv);
            }

            // Render the React icons inside the divs
            try {
              ReactDOM.render(
                <CustomIconWithTooltipSector
                  iconContainerId={iconContainerId}
                  tooltipId={tooltipId}
                />,
                parentDiv
              );;
            } catch (error) {
              console.error("Error rendering the icons:", error);
            }

            // Update the position of the parent div
            const updateParentDivPosition = () => {
              if (chart.container) {
                parentDiv.style.right = '30px';
                parentDiv.style.top = '10px';
              }
            };

            updateParentDivPosition();
            Highcharts.addEvent(chart, 'redraw', updateParentDivPosition);
            window.addEventListener('resize', updateParentDivPosition);
          };

          addIconWithTooltip();
        }
      }
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
      style: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#000'
      }
    },
    legend: {
      enabled: false, // Disable the legend
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return "<b>" + this.point.sector + "</b><br/>" + "<b>" + t("naics") + " " + this.point.sectorCode + "</b><br/>" + `${t("count")}: ` + this.y.toLocaleString('en-CA');
      },
      positioner: function (labelWidth, labelHeight, point) {
        const chart = this.chart;
        let tooltipX = point.plotX + chart.plotLeft + 10;
        let tooltipY = point.plotY + chart.plotTop - labelHeight - 10;

        // Ensure the tooltip doesn't overflow on the right
        if (tooltipX + labelWidth > chart.plotLeft + chart.plotWidth) {
          tooltipX = chart.plotLeft + chart.plotWidth - labelWidth - 10;
        }

        // Ensure the tooltip doesn't overflow on the left
        if (tooltipX < chart.plotLeft) {
          tooltipX = chart.plotLeft + 10;
        }

        // Ensure the tooltip doesn't overflow on the top
        if (tooltipY < chart.plotTop) {
          tooltipY = point.plotY + chart.plotTop + 10;
        }

        return {
          x: tooltipX,
          y: tooltipY
        };
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: t("sectors"),
      },
      categories: ["NAICS3111", "NAICS3112", "NAICS3113"],
    },
    yAxis: {
      title: {
        text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount"),
      },
    },
    series: [
      {
        // name: "Category 1",
        data: [
          {
            y: 9448,
            color: "#de9b61",
          },
          {
            y: 7565,
            color: "#fbb518",
          },
          {
            y: 10404,
            color: "#d460a2",
          },
        ],
        pointWidth: 20,
        borderRadius: 10,
        dataLabels: {
          enabled: true,
          inside: true,
          formatter: function () {
            return this.y.toLocaleString('en-CA');
          },
          style: {
            color: "white",
            textOutline: "0px",
          },
        },
      },
    ],
  });

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    // if (value.type === 'step:after') {
    //   setStepIndex(index); // Update the index on step change
    // }

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
    }

    // if (index >= 3) {
    //   setShowPaginationComponent(true)
    // } else {
    //   setShowPaginationComponent(false)
    // }
    // setShowPaginationComponent(false)
  }

  useEffect(() => {
    if (joyrideStatus) {
      const joyrideSteps = steps?.map(step => {
        const translatedContent = (<>
          {t(step.content)?.split('\\n')?.map((line, index, array) => (
            <p key={index}>{line}</p>
          ))}
        </>)
        return {
          ...step,
          content: translatedContent,
          disableCloseOnEsc: true,
          disableBeacon: true,
          showSkipButton: false,
          showNextButton: true,
          closeButtonText: "Close",
          scrollTo: true,
          // placement: window.innerWidth <= 768 ? 'top' : step.placement
        };
      })
      setSteps(joyrideSteps)
      setHelpRun(true)
    }
  }, [joyrideStatus])

  // useEffect(() => {
  //   const handleResize = () => {
  //     // setIsMobile(window.innerWidth <= 768);
  //     if (window.innerWidth <= 768) {
  //       setOptions((prevOptions) => ({
  //         ...prevOptions,
  //         chart: {...prevOptions.chart, height: 500} 
  //       }))
  //       setChartOptions((prevOptions) => ({
  //         ...prevOptions,
  //         chart: {...prevOptions.chart, height: 500} 
  //       }))
  //     } 
     
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoader(true)
        const urls = {
          employmentCountYears: API_URLS.employmentDataYears
        };
        const [employmentCountYearsResponse] = await Promise.all([
          httpClient({ method: "get", url: urls.employmentCountYears }),
        ])

        if (employmentCountYearsResponse?.result?.years) {
          const reverseYears = employmentCountYearsResponse?.result?.years.reverse()
          setYears(reverseYears)
          setSelectedYear(reverseYears[0])
        }
      } catch (error) {
        console.log(error)
      } finally {
        setInitialDataLoading(true)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    if (initialDataLoading) {
      const fetchData = async () => {
        try {

          setLoader(true)
          const urls = {
            provinceData: API_URLS.employmentDataByProvince.replace("{year}", selectedYear),
            sectorYearly: API_URLS.employmentRaceByYears.replace("{year}", selectedYear),
          };

          const [provinceDataResponse, sectorYearlyResponse] = await Promise.all([
            httpClient({ method: "get", url: urls.provinceData }),
            httpClient({ method: "get", url: urls.sectorYearly }),
          ]);
          if (provinceDataResponse?.result) {

            const res = provinceDataResponse?.result
            const seriesData = [];
            const canadaData = res.counts.slice(0, 1)[0];
            const newArr = res.counts.slice(1);
            for (let c of newArr) {
              for (const feature of canadaMap.features) {
                if (
                  feature.properties.name?.replace(/é/g, "e").toLowerCase() ==
                  c.geographyName
                ) {
                  let languageKey = toCamelCase('province' + feature.properties.name.replace(/é/g, "e"))
                  let dataLabelName = capitalizeString(t(languageKey));
                  feature.properties["hc-key"] = dataLabelName;
                  let yPosition = 0;
                  let xPosition = 0
                  if (languageKey === 'provincePrinceEdwardIsland') {
                    yPosition = -30;
                    xPosition = 12
                  } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                    yPosition = -40
                    xPosition = 50
                  } else if (languageKey === 'provinceNewBrunswick') {
                    yPosition = -8
                    xPosition = -7
                  } else if (languageKey === 'provinceNovaScotia') {
                    yPosition = 10
                    xPosition = 20
                  } else if (languageKey === 'provinceBritishColumbia') {
                    yPosition = -30
                    xPosition = -20
                  } else if (languageKey === 'provinceAlberta') {
                    yPosition = -25
                    xPosition = 0
                  } else if (languageKey === 'provinceSaskatchewan') {
                    yPosition = 15
                    xPosition = 0
                  } else if (languageKey === 'provinceManitoba') {
                    yPosition = -10
                    xPosition = 0
                  } else if (languageKey === 'provinceNunavut') {
                    yPosition = -15
                    xPosition = 15
                  } else if (languageKey === 'provinceNorthwestTerritories') {
                    yPosition = 10
                    xPosition = 15
                  }
                  const data = {
                    'hc-key': feature.properties["hc-key"],
                    name: dataLabelName,
                    value: c.totalCount,
                    percentage: c.percentCount,
                    dataLabels: {
                      y: yPosition,
                      x: xPosition
                    }
                  }

                  seriesData.push(data);
                }
              }
            }
            const resArr = [];
            for (let c in res.counts) {
              let temparr = new Array(3).fill(0);
              temparr[0] = capitalizeFirstLetter(
                t(res.counts[c].geographyLanguageKey)
              );
              temparr[1] = res.counts[c].totalCount;
              temparr[2] = res.counts[c]?.percentCount
                ? res.counts[c]?.percentCount + "%"
                : res.counts[c]?.percentCount;
              resArr[c] = temparr;
            }
            setColData(resArr);
            Highcharts.setOptions({
              lang: {
                thousandsSep: ","
              },
            })
            setOptions((prevOptions) => ({
              ...prevOptions, // Keep other properties unchanged
              subtitle: {
                ...prevOptions.subtitle,
                text: t('employmentInsights.tabProvinceAndTerritory.mapSubtitle') + canadaData.totalCount.toLocaleString('en-CA'), // Update the title text
              },
              series: [
                {
                  ...prevOptions.series[0], // Keep other series properties unchanged
                  data: seriesData, // Update the series data
                },
              ],
            }));
          }

          if (sectorYearlyResponse?.result) {
            const result = sectorYearlyResponse?.result?.count[0]
            let category = [];
            let data = [];
            for (let item of result.yearData) {
              category.push("NAICS" + item.externalId);
              data.push({
                y: item.totalCount,
                sector: t(item.languageKey),
                color: ColorsForSector[item.externalId],
                sectorCode: item.externalId
              });
            }

            setChartOptions((prevState) => ({
              ...prevState,
              subtitle: {
                ...prevState.subtitle, text: t("employmentInsights.tabProvinceAndTerritory.lineChartTitle")
              },
              yAxis: {
                title: {
                  text: t("employmentInsights.tabProvinceAndTerritory.seriesName.employmentCount")
                }
              },
              xAxis: {
                categories: category,
                title: {
                  text: t("sectors")
                },
                labels: {
                  formatter: function () {
                    let iconName = this.value;
                    let icons = `<img src="${IconSetName[iconName]}" alt="SI" height="20px" width="20px" />`;
                    return icons;
                  },
                  useHTML: true, // Enable HTML for labels
                },
              },
              series: [{ data: data }],
            }));
          }
        } catch (error) {
          console.log(error)
        } finally {
          setLoader(false)
        }
      }
      fetchData()
    }
  }, [selectedYear, showTable, t]);

  return (
    <>
      <Loader show={showLoader} />
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      // setStepIndex={handlePaginationHelpTour}
      />
      <h6 className="py-3 px-2">
        {t("employmentInsights.tabProvinceAndTerritory.title")}
      </h6>
      <div className="row gx-0 pt-3 d-flex justify-content-between align-items-center">
        <div className="col-md-2">
          <div className="custom-form-group custom-form-group-sm ps-2">
            <label htmlFor="yearSelect" className="text-black">
              {t("employmentInsights.tabProvinceAndTerritory.titleSelectYear")}
            </label>
            <select
              className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
              value={selectedYear}
              onChange={handleYearChange}
            >
              {years?.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-end">
          <button className="clear_btn" onClick={clearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
            {t("commonSubHeadingClearAll")}
          </button>
          <div className="vertical-line"></div>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>
                Globe View
              </Tooltip>
            )}
            placement="top"
          >
            <button
              onClick={tableGlobeView}
              style={{
                outline: "none",
                border: "1px solid #dee2e6",
                borderRadius: "4px 0 0 4px",
                width: "40px",
                height: '40px',
                backgroundColor: !showTable ? "#666666" : "#f0f0f0",
                color: !showTable ? "#fff" : "",
              }}
            >
              <FaGlobeAmericas />
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>
                Tabular View
              </Tooltip>
            )}
            placement="top"
          >
            <button
              onClick={tableGlobeView}
              style={{
                outline: "none",
                border: "1px solid #dee2e6",
                borderRadius: "0 4px 4px 0",
                width: "40px",
                backgroundColor: showTable ? "#666666" : "#f0f0f0",
                color: showTable ? "#fff" : "",
              }}
            >
              <FaTable />
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div>
        {/* <h4 style={{ textAlign: 'center' }}>Canada</h4> */}
        <Row style={{ padding: '10px' }}>
          <div className="col-md-6" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: '5px', overflow: 'hidden' }}>
            {showTable ? (
              <>
                <p style={{ textAlign: 'center', margin: '10px 0 0', fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '13px', fontWeight: 'bold' }}>{t("workforceInsights.tabProvinceAndTerritory.tableTitle")}</p>
                <Table ref={tableRef} responsive striped bordered style={{ marginTop: "20px", overflow: 'hidden' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          // textAlign: "center",
                          borderRadius: "5px 0 0 0",
                          paddingLeft: '35px',
                          fontSize: '14px'
                        }}
                      >
                        {t(
                          "employmentInsights.tabProvinceAndTerritory.tableColumnHeading.Province"
                        )}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          width: "200px",
                          fontSize: '14px',
                          // paddingRight: '35px'
                        }}
                      >
                        {t(
                          "employmentInsights.tabProvinceAndTerritory.tableColumnHeading.EmploymentCount"
                        )}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          borderRadius: "0 5px 0 0",
                          width: "200px",
                          fontSize: '14px',
                          // paddingRight: '35px'
                        }}
                      >
                        {t(
                          "employmentInsights.tabProvinceAndTerritory.tableColumnHeading.ShareOfTotal"
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {colData?.map((i, index1) => {
                      return (
                        <tr key={index1}
                          onMouseEnter={() => setSelectedIndex(index1)}
                          onMouseLeave={() => setSelectedIndex(null)}
                        >
                          {i?.map((item, index) => {
                            return (
                              <td
                                key={index}
                                style={
                                  {
                                    textAlign: index === 0 ? "left" : "center",
                                    paddingLeft: index === 0 ? '35px' : '',
                                    width: index === 0 ? '350px' : '',
                                    fontWeight: index === 0 ? "bold" : "",
                                    fontSize: '15px',
                                  }
                                }
                              >

                                {index === 0 && i[1] === 0 ? (

                                  <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <span>{item}</span>
                                    <FaCircleInfo
                                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                                      onMouseEnter={() => setHoveredIndex(index1)}
                                      onMouseLeave={() => setHoveredIndex(null)}
                                    />
                                    {selectedIndex === index1 && hoveredIndex === index1 && (
                                      <span ref={spanRef} className={`tooltip-text ${index1 === 13 ? 'tooltip-text-top' : ''}`} style={{
                                        top: index1 === 1 ? '100%' : index1 === 13 ? '-450%' : '-200%',
                                      }}>
                                        {t("disclaimer")}: {t("disclaimerText").split('\\n').map((line, index, array) => (
                                          <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && (
                                              <div style={{ height: '7px' }} />)}
                                          </React.Fragment>
                                        ))}
                                      </span>
                                    )}
                                  </div>
                                ) : index === 1 ? (
                                  item.toLocaleString('en-CA')
                                ) : (
                                  item
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={"mapChart"}
                options={options}
                ref={chartRef}
              />
            )}
          </div>
          <div className="col-md-6" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: '5px' }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        </Row>
      </div>
    </>
  );
};

export default TableViewEmploymentData;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              <b>{t("disclaimer")}:</b>  {t('disclaimerText').split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>

      {/* Second Icon with Tooltip */}
      {/* <div id={`${iconContainerId}-2`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {t("helpTextMap")}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaQuestionCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div> */}
    </>
  );
};

const CustomIconWithTooltipSector = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              <b>{t("disclaimer")}:</b>  {t('disclaimerText').split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
};
