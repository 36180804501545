// import React, { useState, useEffect } from "react";
// import { Tabs, Tab } from "react-bootstrap";
// import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
// import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
// import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
// import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
// import Comparison from "../pages/dashboard/Comparison.tsx";
// import Error403 from "../components/Error403.jsx"
// import { useTranslation } from "react-i18next";
// import { useUser } from "../context/UserContext"

// const TabComponent = ({
//   colData,
//   countByGeography,
//   areaOfStudy,
//   programCountByAreaOfStudy,
//   getTabData,
//   joyrideStatus,
//   setJoyrideStatus
// }) => {
//   const { t } = useTranslation();
//   const { permissions, joyrideSteps } = useUser()
//   const [key, setKey] = useState(
//     JSON.parse(localStorage.getItem("user")).settings.educationalPrograms.tab ||
//     "tab1"
//   ); // State to manage active tab

//   const handleTab = (k) => {
//     setKey(k);
//     getTabData("tabcomponent", k);
//   };

//   const getDefaultActiveTab = () => {
//     if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince')) {
//       return 'tab1';
//     }
//     if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy')) {
//       return 'tab2';
//     }
//     if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction')) {
//       return 'tab3';
//     }
//     if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships')) {
//       return 'tab4';
//     }
//     if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison')) {
//       return 'tab5';
//     }
//     return null; // Default if no permissions match
//   };

//   const hasPermissions = () => (
//     permissions.includes('educationalProgramInsights') && (
//       permissions.includes('educationTabProvince') ||
//       permissions.includes('educationTabAreaOfStudy') ||
//       permissions.includes('educationTabLevelOfInstruction') ||
//       permissions.includes('educationTabAppenticeships') ||
//       permissions.includes('educationTabComparison')
//     )
//   );

//   useEffect(() => {
//     // if (hasPermissions()) {
//       setKey(getDefaultActiveTab());
//     // }
//   }, []);

//   // Show Error403 if no permissions
//   if (!hasPermissions()) {
//     return <Error403 />;
//   }

//   return (
//     <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
//       {/* Fixed Tabs */}
//       <Tabs
//         id="controlled-tab"
//         activeKey={key}
//         onSelect={(k) => handleTab(k)}
//         className="custom_tab"
//       >
//         {permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince') && (
//           <Tab
//             eventKey="tab1"
//             title={t("educationalProgram.tabTitle.provinceAndTerritory")}
//             className="tab_container mt-2"
//           />
//         )}
//         {permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy') && (
//           <Tab
//             eventKey="tab2"
//             title={t("educationalProgram.tabTitle.areaOfStudy")}
//             className="tab_container mt-2"
//           />
//         )}
//         {permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction') && (
//           <Tab
//             eventKey="tab3"
//             title={t("educationalProgram.tabTitle.levelOfInstruction")}
//             className="tab_container mt-2"
//           />
//         )}
//         {permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships') && (
//           <Tab
//             eventKey="tab4"
//             title={t("educationalProgram.tabTitle.apprenticeshipOffered")}
//             className="tab_container mt-2"
//           />
//         )}
//         {permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison') && (
//           <Tab
//             eventKey="tab5"
//             title={t("commonTitleComparison")}
//             className="tab_container mt-2"
//           />
//         )}
//       </Tabs>

//       {/* Scrollable Content Area */}
//       <div className='tab_container' style={{ flex: 1, overflow: "scroll", padding: "20px", marginBottom: "20px", maxHeight: "calc(100vh - 105px)" }}>
//         {key === "tab1" && permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince') && (
//           <TableViewEducationalProgram
//             colData={colData}
//             countByGeography={countByGeography}
//             tabSettings={getTabData}
//             joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}
//             steps={joyrideSteps?.educationalProgramInsights?.tabProvinceTerritory}
//           />
//         )}
//         {key === "tab2" && permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy') && (<HorizontalChartEducationalProgram steps={joyrideSteps?.educationalProgramInsights?.tabAreaOfStudy} joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}/>)}
//         {key === "tab3" && permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction') && <TableByInstitution steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}/>}
//         {key === "tab4" && permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships') && <TableByApprenticeship />}
//         {key === "tab5" && permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison') && <Comparison field={"educationalInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
//       </div>
//     </div>
//   );
// };

// export default TabComponent;

import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Error403 from "../components/Error403.jsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext"
import { Loader } from "./Loader.tsx";

const TabComponent = ({
  colData,
  countByGeography,
  areaOfStudy,
  programCountByAreaOfStudy,
  getTabData,
  joyrideStatus,
  setJoyrideStatus
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const { permissions, joyrideSteps } = useUser()
  const [overflowStyle, setOverflowStyle] = useState("initial");
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(
    JSON.parse(localStorage.getItem("user")).settings.educationalPrograms.tab ||
    "tab1"
  ); // State to manage active tab

  const handleTab = (k) => {
    setKey(k);
    getTabData("tabcomponent", k);
  };

  const getDefaultActiveTab = () => {
    if (permissions.includes('full')) {
      return 'tab1'; // Default to first tab for full access
    }
    if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince')) {
      return 'tab1';
    }
    if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy')) {
      return 'tab2';
    }
    if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction')) {
      return 'tab3';
    }
    if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships')) {
      return 'tab4';
    }
    if (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison')) {
      return 'tab5';
    }
    return null; // Default if no permissions match
  };

  const hasPermissions = () => (
    permissions.includes('full') || // Allow super admin full access
    (permissions.includes('educationalProgramInsights') && (
      permissions.includes('educationTabProvince') ||
      permissions.includes('educationTabAreaOfStudy') ||
      permissions.includes('educationTabLevelOfInstruction') ||
      permissions.includes('educationTabAppenticeships') ||
      permissions.includes('educationTabComparison')
    ))
  );

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      const defaultTab = getDefaultActiveTab();
      setKey(defaultTab);
      setLoading(false)
    }
  }, [permissions]);

  if (loading) {
    return <Loader show={loading}/>;
  }

  // Show Error403 if no permissions
  if (!hasPermissions()) {
    return <Error403 />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      {/* Fixed Tabs */}
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={(k) => handleTab(k)}
        className="custom_tab"
      >
        {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince'))) && (
          <Tab
            eventKey="tab1"
            title={t("educationalProgram.tabTitle.provinceAndTerritory")}
            className="tab_container mt-2"
          />
        )}
        {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy'))) && (
          <Tab
            eventKey="tab2"
            title={t("educationalProgram.tabTitle.areaOfStudy")}
            className="tab_container mt-2"
          />
        )}
        {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction'))) && (
          <Tab
            eventKey="tab3"
            title={t("educationalProgram.tabTitle.levelOfInstruction")}
            className="tab_container mt-2"
          />
        )}
        {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships'))) && (
          <Tab
            eventKey="tab4"
            title={t("educationalProgram.tabTitle.apprenticeshipOffered")}
            className="tab_container mt-2"
          />
        )}
        {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison'))) && (
          <Tab
            eventKey="tab5"
            title={t("commonTitleComparison")}
            className="tab_container mt-2"
          />
        )}
      </Tabs>

      {/* Scrollable Content Area */}
      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)"}}>
        {(key === "tab1" && (permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince')))) && (
          <TableViewEducationalProgram
            colData={colData}
            countByGeography={countByGeography}
            tabSettings={getTabData}
            joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}
            steps={joyrideSteps?.educationalProgramInsights?.tabProvinceTerritory}
          />
        )}
        {(key === "tab2" && (permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy')))) && (
          <HorizontalChartEducationalProgram steps={joyrideSteps?.educationalProgramInsights?.tabAreaOfStudy} joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}/>
        )}
        {(key === "tab3" && (permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction')))) && (
          <TableByInstitution steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}/>
        )}
        {(key === "tab4" && (permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships')))) && (
          <TableByApprenticeship />
        )}
        {(key === "tab5" && (permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison')))) && (
          <Comparison field={"educationalInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
        )}
      </div>
    </div>
  );
};

export default TabComponent;

