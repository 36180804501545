import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/api.ts';

const useHttpClient = () => {
  const navigate = useNavigate();

  const httpClient = async (payload) => {
    const payloadData = { ...payload };

    try {
      const response = await axiosInstance(payloadData);
      const { data: result, headers } = response;
      return {
        result,
        headers,
        error: null,
      };
    } catch (error) {
      if (error?.response?.status === 401 && !payload.url.includes('login')) {
        localStorage.removeItem('token');
        navigate('/login'); // Redirect to login if 401 status
      }

      let myError = error?.response ? error?.response?.data : error;
      return {
        error: myError,
        result: null,
        headers: null,
      };
    }
  };

  return httpClient;
};

export default useHttpClient;
